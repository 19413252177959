<template>
  <div>
    <h2 class="text-center">Welcome Back!</h2>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  data: () => ({}),
  computed: {
    ...mapState("Admin", ["admin"]),
  },
  methods: {},
};
</script>

<style>

.feedback h3 {
  padding: 5px 8px;
  font-weight: 400;
}
.feedback-count {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feedback-count p {
  border-radius: 100%;
  font-size: 20px;
  margin-bottom: 0px;
  margin: 5px 0px;
}
.count {
  color: black !important;
  font-size: 18px !important;
}
</style>
